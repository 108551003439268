import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

function SubsidiarySelector({ subsidary, handleSubsidaryChange }) {
    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="subsidary" required>
                Your Subsidiary
            </InputLabel>
            <Select
                labelId="subsidary-label"
                id="subsidary"
                value={subsidary}
                onChange={handleSubsidaryChange}
                label="Your Subsidiary"
                startAdornment={(
                    <InputAdornment position="start">
                        <MapsHomeWorkIcon />
                    </InputAdornment>
                )}
            >
                <MenuItem value="CD-HQ">CD-HQ 🌍</MenuItem>
                <MenuItem value="CD-CEE">CD-CEE 🇪🇺</MenuItem>
                <MenuItem value="CD-DE">CD-DE 🇩🇪</MenuItem>
                <MenuItem value="CD-FR">CD-FR 🇫🇷</MenuItem>
                <MenuItem value="CD-IT">CD-IT 🇮🇹</MenuItem>
                <MenuItem value="CD-IBERIA">CD-IB 🇪🇸</MenuItem>
                <MenuItem value="CD-KR">CD-KR 🇰🇷 </MenuItem>
                <MenuItem value="CD-INDIA">CD-IN 🇮🇳</MenuItem>
                <MenuItem value="CD-SC">CD-SC 🇸🇪</MenuItem>
                <MenuItem value="CD-UK">CD-UK 🇬🇧</MenuItem>
                <MenuItem value="CD-US">CD-US 🇺🇸</MenuItem>
                <MenuItem value="CD-JP">CD-JP 🇯🇵</MenuItem>
                <MenuItem value="STRATON">STRATON 🇫🇷</MenuItem>
                <MenuItem value="Unknown Subsidiary">Other 🌍</MenuItem>
            </Select>
        </FormControl>
    );
}

export default SubsidiarySelector;
